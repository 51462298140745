import React from 'react'
import { StyleSheet, View, ImageBackground, Vibration, ScrollView, TouchableWithoutFeedback } from 'react-native'
import { Audio } from 'expo-av'
import { Dialog, Text, Portal, Avatar, Divider, Button } from 'react-native-paper'
import axios from 'axios'
import { KHPE_VER, KHPE_CHANGES } from '@env'

import TopBar from './TopBar'

export default class Home extends React.Component {
  constructor () {
    super()

    this.state = {
      homeScreenIMG: '',
      aboutVisible: false,
      sound: ''
    }
  }

  componentDidMount () {
    axios.get('https://tresmos.wtf/khpeHomeScreen')
      .then(data => {
        this.setState({ homeScreenIMG: data.data.khpeHomeScreen })
      })
  }

  playSound = async () => {
    const { sound } = await Audio.Sound.createAsync(
      require('../assets/sound/miiyaav.mp3')
    )
    this.setState({ sound })
    await sound.setVolumeAsync(0.1)
    await sound.playAsync()
  }

  showAbout = () => {
    this.setState({ aboutVisible: true })
    Vibration.vibrate(500)
  }

  render () {
    return (
      <View style={styles.container}>
        <TopBar title='Anasayfa' showAbout={this.showAbout} />

        <ImageBackground
          resizeMode='cover'
          style={styles.image}
          source={this.state.homeScreenIMG ? { uri: this.state.homeScreenIMG } : require('../assets/splash.png')}
        />

        <Portal>
          <Dialog
            dismissable={false}
            style={{ maxHeight: 400 }}
            visible={this.state.aboutVisible}
            onDismiss={() => this.setState({ aboutVisible: false })}
          >
            <Dialog.Title>Obaa Hakkında Zımbırtısı</Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView>
                <TouchableWithoutFeedback onPress={() => {
                  this.playSound()
                }}
                >
                  <Avatar.Image
                    size={128} style={{ alignSelf: 'center' }}
                    source={require('../assets/images/kedu.png')}
                  />
                </TouchableWithoutFeedback>
                <Text style={{
                  fontSize: 25,
                  marginTop: 10,
                  marginBottom: 10,
                  alignSelf: 'center'
                }}
                >Miyav miyav madıfakı
                </Text>
                <Text style={{
                  fontSize: 25,
                  marginTop: 10,
                  marginBottom: 10,
                  alignSelf: 'center'
                }}
                >̿̿ ̿̿ ̿̿ ̿’̿’\̵͇̿̿\з= /ᐠ-ꞈ-ᐟ\ =ε/̵͇̿̿/’̿’̿ ̿ ̿̿ ̿̿ ̿̿
                </Text>
                <Divider style={{ marginBottom: 10 }} />
                <Text style={styles.gizliText}>Versiyon: v31(sj).62</Text>
                <Text style={{
                  fontSize: 15,
                  alignSelf: 'center',
                  fontStyle: 'italic'
                }}
                >
                  {'\n\n\n\n\n\n'}
                  Gerçek Versiyon: {KHPE_VER}{'\n'}
                  Güncelleme Notu: {KHPE_CHANGES}
                </Text>
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                <Text style={{ fontSize: 15, alignSelf: 'center' }}>Kahpe Gaming Gururla Sunar</Text>
              </ScrollView>
            </Dialog.ScrollArea>
            <Dialog.Actions>
              <Button onPress={async () => {
                this.setState({ aboutVisible: false })
                if (this.state.sound) {
                  await this.state.sound.unloadAsync()
                }
              }}
              >( ͡° ͜ʖ ͡°)
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  image: {
    flex: 1,
    justifyContent: 'center'
  },
  gizliText: {
    fontSize: 20,
    alignSelf: 'center'
  }
})
