import * as React from 'react'
import { TouchableWithoutFeedback } from 'react-native'
import { Appbar } from 'react-native-paper'
import supabase from '../lib/initSupabase'

export default class TopBar extends React.Component {
  constructor () {
    super()

    this.state = {
      isLoggedIn: !!(supabase.auth.session())
    }
  }

  componentDidMount () {
    supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'SIGNED_IN':
          this.setState({ isLoggedIn: true })
          break
        case 'SIGNED_OUT':
          this.setState({ isLoggedIn: false })
          break
      }
    })
  }

  render () {
    return (
      <Appbar.Header>
        <Appbar.Content title={this.props.title} />
        {
          (this.props.title == 'Anasayfa' && this.state.isLoggedIn)
            ? <Appbar.Action icon='information-outline' onPress={this.props.showAbout} />
            : null
        }
      </Appbar.Header>
    )
  }
}
