import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { ActivityIndicator, List, Avatar, Divider } from 'react-native-paper'

import supabase from '../../lib/initSupabase'

export default class LakapIsimList extends React.Component {
  constructor () {
    super()
    this.state = {
      isLoading: true,
      names: []
    }

    this.getData()
  }

  getData = async () => {
    await supabase
      .from('profiles')
      .select('id, username, avatarURL, desc')
      .order('username', { ascending: true })
      .then(({ data }) => {
        this.setState({
          names: data,
          isLoading: false
        })
      }
      )
  }

  getAvatar = (avatar) => {
    if (!avatar) return 'a'
    const imgInfo = avatar.split('/')
    const { publicURL, error } = supabase
      .storage.from(imgInfo[0])
      .getPublicUrl(imgInfo[1])
    return publicURL
  }

  navigateToLakaplar = (id, name) => {
    this.props.navigation.navigate('LakapList', { id, name })
  }

  render () {
    return (
      <>
        {
          this.state.isLoading
            ? <ActivityIndicator
                animating
                size='large'
                style={styles.spinnerStyle}
              />
            : null
        }

        <ScrollView style={{ width: '100%', backgroundColor: '#121212' }}>
          {
            this.state.names.map(name =>
              <View key={name.id}>
                <List.Item
                  title={name.username ? name.username : 'Kahpe'}
                  description={name.desc ? name.desc : 'Kahpe'}
                  onPress={() => this.navigateToLakaplar(name.id, name.username)}
                  left={(props) => name.avatarURL
                    ? <Avatar.Image
                        size={64}
                        {...props}
                        source={{ uri: this.getAvatar(name.avatarURL) }}
                      />
                    : <Avatar.Text
                        size={64}
                        {...props}
                        label='KHP'
                      />}
                />
                <Divider />
              </View>
            )
          }
        </ScrollView>
      </>
    )
  }
}

const styles = StyleSheet.create({
  spinnerStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
