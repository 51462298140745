import 'react-native-gesture-handler'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Button, Divider } from 'react-native-paper'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NavigationContainer, DarkTheme } from '@react-navigation/native'
import supabase from '../../lib/initSupabase'
import TopBar from '../TopBar'

import AccountLogin from './AccountLogin'
import AccountProfile from './AccountProfile'
import AccountSignUp from './AccountSignUp'

const Stack = createNativeStackNavigator()

const LoginOrSignUp = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <Button
        icon='account' mode='contained' style={{ marginBottom: 10, width: '70%' }}
        onPress={() => navigation.navigate('AccountLogin')}
      >
        GİRİŞ YAP
      </Button>
      <Divider />
      <Button
        icon='account' mode='contained' style={{ marginBottom: 10, width: '70%' }}
        onPress={() => navigation.navigate('AccountSignUp')}
      >
        ÜYE OL
      </Button>
    </View>
  )
}

export default class Account extends React.Component {
  render () {
    return (
      <NavigationContainer theme={DarkTheme}>
        <TopBar title='Hesap' />
        <Stack.Navigator initialRouteName={supabase.auth.user() ? 'AccountProfile' : 'LoginOrSignUp'}>
          <Stack.Screen name='LoginOrSignUp' component={LoginOrSignUp.bind(this)} options={{ headerShown: false }} />
          <Stack.Screen name='AccountProfile' component={AccountProfile} options={{ headerShown: false }} />
          <Stack.Screen name='AccountLogin' component={AccountLogin} options={{ headerShown: false }} />
          <Stack.Screen name='AccountSignUp' component={AccountSignUp} options={{ headerShown: false }} />
        </Stack.Navigator>
      </NavigationContainer>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
    alignItems: 'center',
    backgroundColor: '#121212',
    flex: 1,
    justifyContent: 'center'
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
    alignSelf: 'stretch'
  }
})
