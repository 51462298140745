import * as React from 'react'
import { View, StyleSheet, ImageBackground } from 'react-native'
import { Text } from 'react-native-paper'

const LoginOlLen = () => {
  return (
    <View style={styles.container}>
      <Text style={{ fontSize: 50 }}>Aga giriş yap pls</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  }
})

export default LoginOlLen
