import 'react-native-gesture-handler'
import 'react-native-url-polyfill/auto'
import React from 'react'
import { registerRootComponent } from 'expo'
import { StatusBar } from 'expo-status-bar'
import * as Updates from 'expo-updates'
import {
  LogBox,
  Alert,
  StyleSheet,
  View,
  Dimensions,
  Platform
} from 'react-native'
import {
  Provider as PaperProvider,
  MD3DarkTheme as DarkThemePaper,
  Snackbar
} from 'react-native-paper'
import NetInfo from '@react-native-community/netinfo'
import { enableScreens } from 'react-native-screens'
import * as Sentry from 'sentry-expo'
import supabase from './lib/initSupabase'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import BottomNav from './Components/BottomNav'

Sentry.init({
  dsn: 'https://27ffb57d529c469b831686e4fa0caaee@o308588.ingest.sentry.io/6125577',
  enableInExpoDevelopment: true,
  debug: false,
  tracesSampleRate: 1.0
})

enableScreens(true)
LogBox.ignoreLogs(['Setting a timer', 'Got a component with the name'])

const paperTheme = {
  ...DarkThemePaper,
  roundness: 3,
  version: 3,
  colors: {
    ...DarkThemePaper.colors
  }
}

export default class Main extends React.Component {
  constructor () {
    super()

    this.state = {
      isLoggedIn: !!(supabase.auth.session()),
      isConnected: true
    }
  }

  componentDidMount () {
    NetInfo.addEventListener(state => {
      this.setState({ isConnected: state.isConnected })
    })

    Updates.addListener(event => {
      if (event.type === 'updateAvailable') {
        Alert.alert(
          'Güncelleme İndirildi',
          'Obaa yeni versiyon vuhuu',
          [
            {
              text: 'kapa ac yab',
              onPress: () => Updates.reloadAsync()
            }
          ],
          {
            cancelable: false
          }
        )
      }
    })
  }

  render () {
    return (
      <View style={styles.container}>
        <PaperProvider theme={paperTheme}>
          <StatusBar style='light' translucent />
          <BottomNav />

          <Snackbar
            duration={300000}
            onDismiss={() => {
            }}
            visible={!this.state.isConnected}
          >
            Krdş netine nold?
          </Snackbar>
        </PaperProvider>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    width: Dimensions.get('window').width >= 1000 ? '30%' : '100%',
    height: '100%',
    alignSelf: 'center'
  }
})

registerRootComponent(Main)
serviceWorkerRegistration.register()
