import 'react-native-gesture-handler'
import React from 'react'
import * as DocumentPicker from 'expo-document-picker'
import { StyleSheet, View, TouchableOpacity, ScrollView, Platform } from 'react-native'
import { Button, Title, Avatar, FAB, ActivityIndicator, Snackbar, TextInput } from 'react-native-paper'
import supabase from '../../lib/initSupabase'
import * as FileSystem from 'expo-file-system'
import { decode } from 'base64-arraybuffer'
import { CommonActions } from '@react-navigation/native'

export default class AccountProfile extends React.Component {
  constructor ({ route }) {
    super()

    this.route = route

    this.state = {
      userSession: [],
      username: '',
      avatarURL: '',
      userDesc: '',
      isLoading: true,
      fabOpen: false,
      editEnabled: false,
      snackbarVisible: false,
      snackbarText: '',
      newUsername: '',
      newDesc: ''
    }

    this.getUserInfo()
  }

  componentDidMount () {
    if (this.route.params) {
      this.setState({
        snackbarVisible: true,
        snackbarText: 'Başarıyla giriş yapıldı.'
      })
    }
  }

  getUserInfo = async () => {
    const userSession = await supabase.auth.session().user
    const { data } = await supabase
      .from('profiles')
      .select('username, avatarURL, desc')
      .eq('id', userSession.id)
      .single()
    if (data.avatarURL) {
      const imgInfo = data.avatarURL.split('/')
      await supabase.storage.from(imgInfo[0]).createSignedUrl(imgInfo[1], 600)
        .then(imgData => {
          this.setState({
            userSession,
            username: data.username ? data.username : 'Kahpe Kullanıcı Adı Koy',
            avatarURL: data.avatarURL ? imgData.signedURL : '',
            userDesc: data.desc ? data.desc : '',
            isLoading: false
          })
        })
    } else {
      this.setState({
        userSession,
        username: data.username ? data.username : 'Kahpe Kullanıcı Adı Koy',
        avatarURL: '',
        userDesc: data.desc ? data.desc : '',
        isLoading: false
      })
    }
    // Handle error
  }

  editInfo = async () => {
    this.setState({
      isLoading: true
    })
    await supabase
      .from('profiles')
      .upsert({
        id: this.state.userSession.id,
        username: (this.state.newUsername === '') ? this.state.username : this.state.newUsername,
        desc: (this.state.newDesc === '') ? this.state.userDesc : this.state.newDesc,
        updated_at: new Date()
      })
      .then(() => {
        this.getUserInfo()
        this.setState({
          isLoading: false,
          snackbarVisible: true,
          snackbarText: 'Profil güncellendi.',
          editEnabled: false
        })
      })
  }

  logout = async () => {
    await supabase.auth.signOut()
    this.setState({ fabOpen: false, editEnabled: false })
    this.props.navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: 'LoginOrSignUp' }
        ]
      })
    )
  }

  editAvatar = async () => {
    if (Platform.OS === 'web') {
      this.setState({ snackbarVisible: true, snackbarText: 'Web üzerinden pp değiştiremessin. Neden diye sorma. Değiştirmek istiyosan uygulamayı indir.' })
    } else {
      DocumentPicker.getDocumentAsync({
        type: 'image/*',
        copyToCacheDirectory: true
      })
        .then(async file => {
          if (file.type === 'cancel') return
          this.setState({ snackbarVisible: true, snackbarText: 'PP Yükleniyor...' })
          const image = file.uri
          const fileExt = file.name.split('.').pop()
          const fileName = `${Math.floor(Math.random() * 1000000000)}.${fileExt}`

          FileSystem.readAsStringAsync(image, { encoding: FileSystem.EncodingType.Base64 })
            .then(async data => {
              await supabase
                .storage
                .from('avatars')
                .upload(`${fileName}`, decode(data), {
                  contentType: 'image/png'
                })
                .then(async imageObj => {
                  await supabase
                    .from('profiles')
                    .upsert({
                      id: this.state.userSession.id,
                      avatarURL: imageObj.data.Key,
                      updated_at: new Date()
                    }).then(() => {
                      this.getUserInfo()
                      this.setState({ snackbarVisible: true, snackbarText: 'PP Başarıyla yüklendi.' })
                    })
                })
            })
        })
    }
  }

  render () {
    return (
      <View style={styles.container}>
        {
          this.state.isLoading
            ? <ActivityIndicator
                animating
                size='large'
                style={styles.spinnerStyle}
              />
            : null
        }
        <ScrollView style={{ width: '100%' }}>
          <View>
            <TouchableOpacity disabled={!this.state.editEnabled} onPress={() => this.editAvatar()}>
              {
                this.state.avatarURL
                  ? <Avatar.Image
                      size={180}
                      style={styles.avatar}
                      source={{ uri: this.state.avatarURL }}
                    />
                  : <Avatar.Text
                      style={styles.avatar}
                      size={180}
                      label='KHP'
                    />
              }
            </TouchableOpacity>
          </View>
          <Title style={styles.username}>{this.state.username}</Title>
          <Title style={styles.desc}>{this.state.userDesc}</Title>
          {
            this.state.editEnabled
              ? <View style={{ width: '100%', alignItems: 'center' }}>
                <TextInput
                  label='Kullanıcı Adı'
                  mode='outlined'
                  value={this.state.newUsername}
                  style={{
                    width: '85%'
                  }}
                  onChangeText={text => this.setState({ newUsername: text })}
                />
                <TextInput
                  label='Açıklama'
                  mode='outlined'
                  value={this.state.newDesc}
                  style={{
                    width: '85%'
                  }}
                  onChangeText={text => this.setState({ newDesc: text })}
                />
                <Button
                  icon='pencil'
                  mode='contained'
                  style={{ marginTop: 20 }}
                  onPress={() => this.editInfo()}
                >
                  Onayla
                </Button>
              </View>
              : null
          }
        </ScrollView>
        <FAB.Group
          open={this.state.fabOpen}
          icon={this.state.fabOpen ? 'pencil-circle' : 'pencil-circle-outline'}
          actions={[
            {
              icon: 'pencil',
              small: false,
              label: 'Düzenle',
              onPress: () => this.setState({ fabOpen: false, editEnabled: !this.state.editEnabled })
            },
            {
              icon: 'logout',
              label: 'Çıkış Yap',
              onPress: () => this.logout(),
              small: false
            }
          ]}
          onStateChange={() => {
          }}
          onPress={() => {
            this.setState({ fabOpen: !this.state.fabOpen })
          }}
        />
        <Snackbar
          duration={2000}
          onDismiss={() => this.setState({ snackbarVisible: false })}
          visible={this.state.snackbarVisible}
        >
          {this.state.snackbarText}
        </Snackbar>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#121212'
  },
  avatar: {
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center'
  },
  username: {
    fontSize: 30,
    marginBottom: 0,
    alignSelf: 'center'
  },
  desc: {
    fontSize: 18,
    fontStyle: 'italic',
    marginBottom: 30,
    alignSelf: 'center'
  },
  spinnerStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
