import 'react-native-gesture-handler'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NavigationContainer, DarkTheme } from '@react-navigation/native'
import TopBar from '../TopBar'
import supabase from '../../lib/initSupabase'

import LoginOlLen from '../LoginOlLen'
import LakapIsimList from './LakapIsimList'
import LakapList from './LakapList'

const Stack = createNativeStackNavigator()

export default class Lakaplar extends React.Component {
  constructor () {
    super()

    this.state = {
      isLoggedIn: !!(supabase.auth.session())
    }
  }

  componentDidMount () {
    supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'SIGNED_IN':
          this.setState({ isLoggedIn: true })
          break
        case 'SIGNED_OUT':
          this.setState({ isLoggedIn: false })
          break
      }
    })
  }

  render () {
    return (
      <>
        {
          this.state.isLoggedIn
            ? <NavigationContainer theme={DarkTheme}>
              <TopBar title='Lakapmaca' />
              <Stack.Navigator initialRouteName='Home'>
                <Stack.Screen name='Home' component={LakapIsimList} options={{ headerShown: false }} />
                <Stack.Screen name='LakapList' component={LakapList} options={{ headerShown: false }} />
              </Stack.Navigator>
            </NavigationContainer>
            : <LoginOlLen />
        }
      </>
    )
  }
}
