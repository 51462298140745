import 'react-native-gesture-handler'
import React from 'react'
import { StyleSheet, View, Alert, Keyboard } from 'react-native'
import { Button, TextInput, Title, ActivityIndicator, Snackbar } from 'react-native-paper'
import supabase from '../../lib/initSupabase'
import { CommonActions } from '@react-navigation/native'

export default class AccountLogin extends React.Component {
  constructor () {
    super()

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      snackbarVisible: false,
      snackbarText: ''
    }
  }

  loginButton = async () => {
    const { email, password } = this.state
    Keyboard.dismiss()
    if (!email || !password) {
      this.setState({
        snackbarVisible: true,
        snackbarText: '-_-'
      })
    } else {
      this.setState({ isLoading: true })
      const { user, session, error } = await supabase.auth.signIn({
        email,
        password
      })
      if (error) {
        switch (error.message) {
          case 'Invalid login credentials':
            this.setState({
              snackbarVisible: true,
              snackbarText: 'Aga şifren yada mailin yanlş',
              isLoading: false
            })
            break
          case 'Email not confirmed':
            this.setState({
              snackbarVisible: true,
              snackbarText: 'Aga mailine gelen linke tıklayıp dorulasana hesabını',
              isLoading: false
            })
            break
          default:
            this.setState({
              snackbarVisible: true,
              snackbarText: 'Biyerlerde bişiler oluyo noluyo',
              isLoading: false
            })
        }
        console.log(error)
      } else {
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: 'AccountProfile', params: { loginSuccess: true } }
            ]
          })
        )
      }
    }
  }

  render () {
    return (
      <View style={styles.container}>
        <Title style={{ alignSelf: 'center' }}>Giriş Yab</Title>
        <TextInput
          label='Email'
          value={this.state.email}
          mode='outlined'
          keyboardType='email-address'
          autoCapitalize='none'
          disabled={this.state.isLoading}
          style={{ marginLeft: 30, marginRight: 30, marginBottom: 10 }}
          left={<TextInput.Icon name='at' />}
          onChangeText={email => this.setState({ email })}
        />
        <TextInput
          label='Şifre'
          value={this.state.password}
          mode='outlined'
          secureTextEntry
          autoCapitalize='none'
          disabled={this.state.isLoading}
          style={{ marginLeft: 30, marginRight: 30, marginBottom: 20 }}
          left={<TextInput.Icon name='key-variant' />}
          onChangeText={password => this.setState({ password })}
        />
        <Button
          icon='account'
          mode='contained'
          disabled={this.state.isLoading}
          style={{ marginLeft: 30, marginRight: 30, marginBottom: 20 }}
          onPress={() => this.loginButton()}
        >
          GİRİŞ YAP
        </Button>
        <Snackbar
          duration={1500}
          onDismiss={() => this.setState({ snackbarVisible: false })}
          visible={this.state.snackbarVisible}
        >
          {this.state.snackbarText}
        </Snackbar>
        {
          this.state.isLoading
            ? <ActivityIndicator
                animating
                size='large'
              />
            : null
        }
      </View>
    )
  }
}

const
  styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: '#121212'
    }
  })
