import React from 'react'
import { ScrollView, StyleSheet, View, Alert, Keyboard } from 'react-native'
import {
  List,
  ActivityIndicator,
  FAB,
  Portal,
  Dialog,
  TextInput,
  Button,
  Divider,
  Snackbar,
  Text
} from 'react-native-paper'
import supabase from '../../lib/initSupabase'
import { CommonActions } from '@react-navigation/native'

class SozlerList extends React.Component {
  constructor ({ route, navigation }) {
    super()
    this.route = route
    this.navigation = navigation

    this.state = {
      id: this.route.params.id,
      name: this.route.params.name,
      nameData: [],
      userSession: supabase.auth.user(),
      isLoading: true,
      inputVisible: false,
      value: '',
      snackbarVisible: false,
      snackbarText: '',
      currentSozText: '',
      currentSozVisible: false,
      textInputDisabled: false
    }

    this.getData(this.state.id)
  }

  getData = async (id) => {
    await supabase
      .from('sozler')
      .select('id, soz, created_at, addedBy')
      .eq('user_id', id)
      .order('created_at', { ascending: false })
      .then(async ({ data }) => {
        const tempData = data
        if (tempData.length === 0) {
          this.setState({
            nameData: [{ id: 'bos', soz: 'kahpe manalı bişiler söyle de ekleyek' }],
            isLoading: false
          })
        } else {
          await supabase
            .from('profiles')
            .select('id, username')
            .then(({ data }) => {
              tempData.map((item) => {
                const dataArr = this.state.nameData
                const addedBy = data.find(element => element.id == item.addedBy)

                dataArr.push({
                  id: item.id,
                  soz: item.soz,
                  addedBy: addedBy.username,
                  neZaman: item.created_at
                })
                this.setState({
                  nameData: dataArr,
                  isLoading: false
                })
              })
            })
        }
      })
  }

  addSoz = async () => {
    if (this.state.value === '') {
      Alert.alert(
        'Hata',
        'Aga boş yapma aga'
      )
    } else {
      Keyboard.dismiss()
      this.setState({ isLoading: true, textInputDisabled: true })
      await supabase
        .from('sozler')
        .insert(
          {
            soz: this.state.value,
            user_id: this.state.id,
            addedBy: this.state.userSession.id
          }
        )
        .then(async ({ data }) => {
          await supabase
            .from('loglar')
            .insert(
              {
                kim: this.state.userSession.id,
                kime: this.state.id,
                ne: 'söz',
                itemID: data[0].id
              }
            )
            .then(({ data }) => {
              this.setState({
                inputVisible: false,
                value: '',
                snackbarVisible: true,
                snackbarText: 'Söz Eklendi',
                textInputDisabled: false,
                nameData: []
              })
              this.getData(this.state.id)
            })
        })
    }
  }

  render () {
    return (
      <>
        <Portal>
          {
            this.state.isLoading
              ? <ActivityIndicator
                  animating
                  size='large'
                  style={styles.spinnerStyle}
                />
              : null
          }
        </Portal>
        <ScrollView style={{ width: '100%', backgroundColor: '#121212' }}>
          {
            this.state.nameData.map((item) =>
              <View key={item.id}>
                <List.Item
                  title={item.soz}
                  description={item.addedBy ? `${new Date(item.neZaman).toLocaleTimeString()} - ${new Date(item.neZaman).toLocaleDateString()} - ${item.addedBy}` : null}
                  onPress={() => this.setState({
                    currentSozText: item.soz,
                    currentSozVisible: true
                  })}
                />
                <Divider />
              </View>
            )
          }
        </ScrollView>
        <Portal>
          <Dialog
            visible={this.state.currentSozVisible}
            onDismiss={() => this.setState({
              currentSozVisible: false,
              currentSozText: ''
            })}
          >
            <Dialog.Title>{this.state.name} demiş ki:</Dialog.Title>
            <Dialog.Content>
              <Text style={{ fontSize: 15 }}>{this.state.currentSozText}</Text>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => this.setState({
                currentSozVisible: false,
                currentSozText: ''
              })}
              >Wow çok ii söz
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <Portal>
          <Dialog visible={this.state.inputVisible} onDismiss={() => this.setState({ inputVisible: false })}>
            <Dialog.Title>Söz eklenecek kişi: {this.state.name}</Dialog.Title>
            <Dialog.Content>
              <TextInput
                label='Söz'
                disabled={this.state.textInputDisabled}
                onChangeText={text => this.setState({ value: text })}
              />
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => this.addSoz()}>Ekle</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        {
          supabase.auth.session()
            ? <>
              <FAB
                style={styles.fab}
                icon='plus'
                onPress={() => this.setState({ inputVisible: true })}
                onLongPress={() => this.props.navigation.dispatch(
                  CommonActions.reset({
                    index: 1,
                    routes: [
                      { name: 'Home' }
                    ]
                  })
                )}
              />
              </>
            : null
        }
        <Snackbar
          duration={1500}
          onDismiss={() => this.setState({ snackbarVisible: false })}
          visible={this.state.snackbarVisible}
        >
          {this.state.snackbarText}
        </Snackbar>
      </>
    )
  }
}

const styles = StyleSheet.create({
  spinnerStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0
  }
})

export default SozlerList
