import * as React from 'react'
import { BottomNavigation } from 'react-native-paper'

import Home from './Home'
import Lakaplar from './Lakaplar/Lakaplar'
import Sozler from './Sozler/Sozler'
import Account from './Account/Account'
import Loglar from './Loglar/Loglar'

export default class BottomNav extends React.Component {
  constructor() {
    super()

    this.state = {
      navState: {
        index: 0,
        routes: [
          { key: 'anasayfa', title: 'Anasayfa', focusedIcon: 'home', unfocusedIcon: 'home-outline' },
          { key: 'lakaplar', title: 'Lakapmaca', focusedIcon: 'tag', unfocusedIcon: 'tag-outline' },
          { key: 'sozler', title: 'Sözler', focusedIcon: 'newspaper-variant', unfocusedIcon: 'newspaper-variant-outline' },
          { key: 'loglar', title: 'Loglar', focusedIcon: 'clipboard-list', unfocusedIcon: 'clipboard-list-outline' },
          { key: 'account', title: 'Hesap', focusedIcon: 'account', unfocusedIcon: 'account-outline' }
        ]
      }
    }
  }

  renderScene = () => {
    BottomNavigation.SceneMap(this.state.navState.routes, {
      anasayfa: Home,
      lakaplar: Lakaplar,
      sozler: Sozler,
      loglar: Loglar,
      account: Account
    })
  }

  render() {
    const renderScene = BottomNavigation.SceneMap({
      anasayfa: Home,
      lakaplar: Lakaplar,
      sozler: Sozler,
      loglar: Loglar,
      account: Account
    })

    return (
      <BottomNavigation
        navigationState={this.state.navState}
        onIndexChange={index => this.setState(prevState => ({ navState: { ...prevState.navState, index } }))}
        renderScene={renderScene}
        sceneAnimationEnabled
        sceneAnimationType='shifting'
      />
    )
  }
};
