import 'react-native-gesture-handler'
import React from 'react'
import { StyleSheet, View, ScrollView, RefreshControl } from 'react-native'
import { Text, ActivityIndicator, Divider, List, Portal } from 'react-native-paper'
import supabase from '../../lib/initSupabase'

import TopBar from '../TopBar'
import LoginOlLen from '../LoginOlLen'

export default class Loglar extends React.Component {
  constructor () {
    super()

    this.state = {
      isLoggedIn: !!(supabase.auth.session()),
      isLoading: true,
      data: [],
      refreshing: false
    }
  }

  componentDidMount () {
    supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'SIGNED_IN':
          this.setState({ isLoggedIn: true })
          break
        case 'SIGNED_OUT':
          this.setState({ isLoggedIn: false })
          break
      }
    })
    this.getData()
  }

  getData = async () => {
    await supabase
      .from('loglar')
      .select()
      .order('created_at', { ascending: false })
      .then(async ({ data }) => {
        const tempData = data
        await supabase
          .from('profiles')
          .select('id, username')
          .then(({ data }) => {
            tempData.map((item) => {
              const dataArr = this.state.data
              const kim = data.find(element => element.id == item.kim)
              const kime = data.find(element => element.id == item.kime)
              dataArr.push({
                kim: kim.username,
                kime: kime.username,
                ne: item.ne,
                id: item.id,
                neZaman: item.created_at
              })
              this.setState({ data: dataArr, refreshing: false, isLoading: false })
            })
          })
      })
  }

  refreshData = () => {
    this.setState({ refreshing: true, isLoading: true, data: [] })
    this.getData()
  }

  render () {
    return (
      <>
        {
          this.state.isLoggedIn
            ? <>
              <TopBar title='Loglar' />
              <ScrollView
                style={{ width: '100%', backgroundColor: '#121212' }}
                refreshControl={
                  <RefreshControl
                    refreshing={this.state.refreshing}
                    onRefresh={this.refreshData}
                  />
                }
              >
                <Portal>
                  {
                    this.state.isLoading
                      ? <ActivityIndicator
                          animating
                          size='large'
                          style={styles.spinnerStyle}
                        />
                      : null
                  }
                </Portal>
                {
                  this.state.data.map((item) =>
                    <View key={item.id}>
                      <List.Item
                        title={`${item.kim}, ${item.kime} adlı kişiye ${item.ne} ekledi.`}
                        description={`${new Date(item.neZaman).toLocaleTimeString()} - ${new Date(item.neZaman).toLocaleDateString()}`}
                      />
                      <Divider />
                    </View>
                  )
                }
              </ScrollView>
            </>
            : <LoginOlLen />
        }
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  spinnerStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
