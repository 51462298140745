import 'react-native-gesture-handler'
import React from 'react'
import { StyleSheet, View, Alert, Keyboard } from 'react-native'
import { Button, TextInput, Title, ActivityIndicator, Snackbar } from 'react-native-paper'
import supabase from '../../lib/initSupabase'
import { CommonActions } from '@react-navigation/native'

export default class AccountPassword extends React.Component {
  constructor () {
    super()

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      snackbarVisible: false,
      snackbarText: ''
    }
  }

  signUpButton = async () => {
    const { email, password } = this.state
    Keyboard.dismiss()
    if (!email || !password) {
      this.setState({
        snackbarVisible: true,
        snackbarText: '-_-'
      })
    } else if (email.length <= 10 || password.length <= 5) {
      this.setState({
        snackbarVisible: true,
        snackbarText: 'Doru düzgün email gir ve şifren de en az 6 karakter olsn'
      })
    } else {
      this.setState({ isLoading: true })
      const { user, error } = await supabase.auth.signUp({
        email: this.state.email,
        password: this.state.password
      })
      if (error) {
        this.setState({
          isLoading: false,
          snackbarVisible: true,
          snackbarText: 'Aga noluyo aga bi sorun var'
        })
        console.log(error)
      } else {
        this.setState({ isLoading: false })
        Alert.alert(
          'Başarılı',
          'Emailini kontrol et pls yoksa döverm',
          [
            {
              text: 'tm',
              onPress: () => {
                this.props.navigation.dispatch(
                  CommonActions.reset({
                    index: 1,
                    routes: [
                      { name: 'LoginOrSignUp' }
                    ]
                  })
                )
              }
            }
          ]
        )
        console.log(user)
      }
    }
  }

  render () {
    return (
      <View style={styles.container}>
        <Title style={{ alignSelf: 'center' }}>Üye Ol</Title>
        <TextInput
          label='Email'
          mode='outlined'
          keyboardType='email-address'
          autoCapitalize='none'
          disabled={this.state.isLoading}
          style={{ marginLeft: 30, marginRight: 30, marginBottom: 10 }}
          left={<TextInput.Icon name='at' />}
          onChangeText={email => this.setState({ email })}
        />
        <TextInput
          label='Şifre'
          mode='outlined'
          secureTextEntry
          autoCapitalize='none'
          disabled={this.state.isLoading}
          style={{ marginLeft: 30, marginRight: 30, marginBottom: 20 }}
          left={<TextInput.Icon name='key-variant' />}
          onChangeText={password => this.setState({ password })}
        />
        <Button
          icon='account'
          mode='contained'
          disabled={this.state.isLoading}
          style={{ marginLeft: 30, marginRight: 30, marginBottom: 20 }}
          onPress={() => this.signUpButton()}
        >
          ÜYE OL
        </Button>
        <Snackbar
          duration={1500}
          onDismiss={() => this.setState({ snackbarVisible: false })}
          visible={this.state.snackbarVisible}
        >
          {this.state.snackbarText}
        </Snackbar>
        {
          this.state.isLoading
            ? <ActivityIndicator
                animating
                size='large'
              />
            : null
        }
      </View>
    )
  }
}

const
  styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: '#121212'
    }
  })
